import amazon from '../assets/amazon.png'
import jasaron from '../assets/jasaron_learning.png'
import recipe from '../assets/recipe-app.png'
import hulu from '../assets/hulu.png'

export const data=[
    {
        id:1,
        name:"Amazon Project",
        image:amazon,
        github:"https://github.com/Aaronlb912/Amazon-Copy",
        live:"https://fir-ff812.web.app/",
    },
    {
        id:2,
        name:"Jasaron Learning Website",
        image:jasaron,
        github:"",
        live:"https://www.jasaronlearninginstitute.com/",
    },
    {
        id:3,
        name:"Recipe Application",
        image:recipe,
        github:"https://github.com/Aaronlb912/recipe-app",
        live:"https://abrecipeapp.netlify.app/",
    },
    {
        id:4,
        name:"Hulu Project",
        image:hulu,
        github:"https://github.com/Aaronlb912/Hulu-Clone",
        live:"https://hulu-clone-gules-six.vercel.app/",
    },


]